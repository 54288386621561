var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "multiple_icon_popup" }, [
    _c("div", { staticClass: "title" }, [_vm._v("请选择App安装图标")]),
    _c(
      "div",
      { staticClass: "icons" },
      _vm._l(_vm.icon_array, function (item, key) {
        return _c(
          "div",
          {
            key: key,
            on: {
              click: function ($event) {
                return _vm.selectIcon(key)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "icon" },
              [
                _c("DecodeImage", { attrs: { path: item.icon } }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selected === key,
                      expression: "selected === key",
                    },
                  ],
                  staticClass: "selected",
                  attrs: {
                    src: require("@/pages/apple/index/assets/icon/selected.png"),
                  },
                }),
              ],
              1
            ),
            _c("span", [_vm._v(_vm._s(item.name))]),
          ]
        )
      }),
      0
    ),
    _c("div", { staticClass: "submit", on: { click: _vm.submit } }, [
      _vm._v("确认"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }