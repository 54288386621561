const multiple_icon = {
  'apple': [
    { name: '91 视频', icon: require('@/pages/apple/index/assets/icon/icon_0.png') },
    { name: '闪电防毒', icon: require('@/pages/apple/index/assets/icon/icon_1.png') },
    { name: ' APP 357', icon: require('@/pages/apple/index/assets/icon/icon_2.png') }
  ],
  'bs': [],
  'xindaxiang': [],
  'guimi': [],
  'lu': [],
  'momo': []
}

export default multiple_icon
