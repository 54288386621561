<script>
import multiple_icon from '@/../config/multiple_icon'
import DecodeImage from '@/components/common/DecodeImage.vue'

export default {
  name: 'MultipleIconPopup',
  components: { DecodeImage },
  props: {
    app_code: {
      type: String,
      required: true
    }
  },
  computed: {
    icon_array() {
      return multiple_icon[this.app_code]
    }
  },
  data() {
    return {
      selected: 0
    }
  },
  methods: {
    selectIcon(index) {
      this.selected = index
    },
    submit() {
      this.$emit('selectIcon', this.selected)
    }
  }
}
</script>

<template>
  <div class="multiple_icon_popup">
    <div class="title">请选择App安装图标</div>
    <div class="icons">
      <div v-for="(item, key) in icon_array" :key="key" @click="selectIcon(key)">
        <div class="icon">
          <DecodeImage :path="item.icon" />
          <img v-show="selected === key" class="selected" src="@/pages/apple/index/assets/icon/selected.png" />
        </div>
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="submit" @click="submit">确认</div>
  </div>
</template>

<style scoped>
.multiple_icon_popup {
  margin: 20px 25px;
}

.title {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 110% */
  padding-bottom: 20px;
}

.icons {
  margin: 0 20px;
  display: flex;
  gap: 20px;
}

.icon {
  position: relative;
  margin-bottom: 6px;
}

span {
  color: #222;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.selected {
  position: absolute;
  width: 20px;
  right: 0px;
  bottom: 0px;
}

.submit {
  margin-top: 20px;
  color: #FFF;
  text-align: center;

  /* Button 1 */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */

  border-radius: 6px;
  background: #F683A2;
  padding: 13px;
}
</style>
