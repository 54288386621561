<template>
  <DecodeImage
    class="common-page-content"
    data-cy="page-content"
    :background-image-mode="true"
    :path="backgroundImg"
  >
    <div class="tutorial-logo">
      <slot name="logo">
        <DecodeImage class="logo" :path="logoImg" />
      </slot>
    </div>

    <div class="tutorial-title">
      <slot name="title">
        <div class="common-page-title">{{ title }}</div>
      </slot>
    </div>

    <div class="tutorial-download">
      <slot name="download">
        <div
          class="brand-common-btn"
          data-cy="download-button"
          @click="clickDownload"
        >
          立即下载
        </div>
      </slot>
    </div>

    <div class="tutorial-steps">
      <slot name="steps">
        <DecodeImage class="step" :path="stepImg" />
      </slot>
    </div>

    <div class="tutorial-join-group">
      <slot name="join-group">
        <JoinGroup :app-code="appCode" />
      </slot>
    </div>

    <van-popup
      v-model="showMultipleIconPopup"
      :style="{ width: '80%' }"
      get-container="#app"
      round
    >
      <MultipleIconPopup :app_code="appCode" @selectIcon="download"/>
    </van-popup>
  </DecodeImage>
</template>

<script>
import DecodeImage from '@/components/common/DecodeImage.vue'
import JoinGroup from '@/components/common/join-group'
import stepImg from '@/assets/images/apple_light_step.png'
import { getQueryValues, uploadDownloadEvent } from '@/../utils'
import { CLICK_DOWNLOAD, GO_TO_DOWNLOAD_PAGE, IOS_LIGHT } from '@/../config'
import { getIOSMobileConfig } from '@/service'
import MultipleIconPopup from '@/components/common/ios-light/MultipleIconPopup.vue'

import Vue from 'vue'
import { Popup } from 'vant'
import 'vant/lib/index.css'

Vue.use(Popup)

export default {
  name: 'IosLight',
  components: { JoinGroup, DecodeImage, MultipleIconPopup },
  props: {
    backgroundImg: {
      type: String,
      default: require('@/pages/momo/index/assets/images/common_bg.png'),
    },
    logoImg: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'iOS版安装教程',
    },
    appCode: {
      type: String,
      default: '',
    },
    multipleIcon: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      stepImg,
      showMultipleIconPopup: false,
    }
  },
  methods: {
    clickDownload() {
      if (this.multipleIcon) {
        this.showMultipleIconPopup = true
      } else {
        this.download(0)
      }
    },
    getMobileConfigUrl(icon_idx = 0) {
      const { channel_code, invite_code } = getQueryValues()
      let url = `/apis/m_appstore/iOS/downloadApp?app_code=${this.appCode}&channel_code=${channel_code}&icon_idx=${icon_idx}`
      if (invite_code) {
        url = url + `&invite_code=${invite_code}`
      }
      return url
    },
    async download(icon_idx = 0) {
      uploadDownloadEvent(this.appCode, CLICK_DOWNLOAD, IOS_LIGHT)
      const url = this.getMobileConfigUrl(icon_idx)
      try {
        await getIOSMobileConfig()
      } catch (error) {
        let t = setTimeout(function () {
          document.location.href = url
          clearTimeout(t)
          t = null
        }, 100)
      }
    },
  },
  created() {
    uploadDownloadEvent(this.appCode, GO_TO_DOWNLOAD_PAGE, IOS_LIGHT)
  },
}
</script>

<style scoped>
.tutorial-logo {
  margin-bottom: 20px;
}

.tutorial-download .brand-common-btn {
  width: 260px;
  margin: 16px auto 24px;
}

.tutorial-join-group ::v-deep .join-group .join-text {
  color: #fff;
  text-decoration: none;
}
</style>
