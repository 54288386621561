<template>
  <div class="wrapper">
    <template v-if="androidButtonImg">
      <a
        class="brand-common-btn"
        :style="buttonStyle"
        @click="getDownloadUrl"
      >
        <DecodeImage
          class="button-img"
          :path="androidButtonImg"
        />
      </a>
    </template>

    <div
      v-else
      class="wrapper__buttons"
    >
      <a
        data-cy="download-button"
        class="brand-common-btn"
        :style="buttonStyle"
        @click="getDownloadUrl"
      >
        <DecodeImage
          v-if="buttonBackgroundImg"
          class="button-img"
          :path="buttonBackgroundImg"
        />
        <span :style="buttonBeforeStyle" />
        <span class="button-title">下载</span>
        <span :style="buttonAfterStyle" />
      </a>
    </div>
    <div class="tip_text">
      <a
        data-cy="solution-link"
        class="solution"
        href="/solution.html"
        @click="clickHelpDoc"
        target="_blank"
      >
        成人APP会被误报病毒 忽略即可(查看帮助)
      </a>
    </div>

    <a
      :href="android_download_url"
      ref="android_download_link"
      style="display: none"
    />
  </div>
</template>

<script>
import {
  ANDROID,
  ANDROID_WARN,
  COPY_SUCCESS,
  CLICK_DOWNLOAD,
  GET_DOWNLOAD_URL,
} from '@/../config'
import { CLICK_SOLUTION } from '@/../config/event_name'
import {
  clickCopy,
  getQueryValues,
  uploadDownloadEvent,
  triggerDownloadApk,
} from '@/../utils'
import { getAndroidDownloadUrl } from '@/service'
import DecodeImage from '@/components/common/DecodeImage.vue'
export default {
  components: {
    DecodeImage,
  },
  props: {
    showWarn: {
      type: Boolean,
      default: true,
    },
    appCode: {
      type: String,
      default: 'apple',
    },
    buttonStyle: {
      type: Object,
      default: () => ({}),
    },
    buttonBeforeStyle: {
      type: Object,
      default: () => ({}),
    },
    buttonAfterStyle: {
      type: Object,
      default: () => ({}),
    },
    buttonBackgroundImg: {
      type: String,
      default: '',
    },
    androidButtonImg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ANDROID_WARN,
      android_download_url: '',
    }
  },
  mounted() {
    if (window.auto_download) {
      this.getDownloadUrl()
    }
  },
  methods: {
    async getDownloadUrl() {
      uploadDownloadEvent(this.appCode, CLICK_DOWNLOAD, ANDROID)
      const query = getQueryValues()
      if (query.invite_code) {
        let s = JSON.stringify(query)
        let cb = () => {
          uploadDownloadEvent(this.appCode, COPY_SUCCESS, ANDROID)
        }
        clickCopy(s, '', true, cb)
      }
      const { data } = await getAndroidDownloadUrl(this.appCode)
      this.android_download_url = data.android_url
      this.download()
    },
    clickHelpDoc() {
      uploadDownloadEvent(this.appCode, CLICK_SOLUTION, ANDROID)
    },
    download() {
      triggerDownloadApk(this.android_download_url)
      uploadDownloadEvent(this.appCode, GET_DOWNLOAD_URL, ANDROID)
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.wrapper__buttons {
  width: 85%;
  display: flex;
  justify-content: center;
  column-gap: 10px;
  margin: auto;
}

.brand-common-btn {
  flex-grow: 1;
  height: 45px;
  padding: 0;
  font-size: 20px;
  position: relative;
}

.tip_text {
  width: 85%;
  margin-top: 10px;
  border-radius: 4px;
  padding: 5px 10px;
  background: rgba(50, 3, 3, 0.50);
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
.solution {
  color: #fff;
}

.button-img {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.button-title {
  z-index: 3;
}
</style>
